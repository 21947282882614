<template>
  <v-card :loading="loading" class="main-card mb-3 card">
    <div class="no-gutters row" :class="{'row-cols-5':$vuetify.breakpoint.mdAndUp}">
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle ">
            <div class="icon-wrapper-bg bg-info"></div>
            <v-icon size="26">euro</v-icon></div>
          <div class="widget-numbers">{{ stats.total| currency}}</div>
          <div class="widget-subheading">{{$t('generic.lang_total')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-primary"></div>
            <font-awesome-icon :icon="['fal','tachometer-alt-average']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.onlineTotal| currency}}</div>
          <div class="widget-subheading">{{$t('generic.lang_onlineTotal')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'">
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-alternate"></div>
            <font-awesome-icon :icon="['fal','store']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.kioskTotal| currency}}</div>
          <div class="widget-subheading">{{$t('generic.lang_kioskTotal')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'">
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-alternate"></div>
            <font-awesome-icon :icon="['fal','cash-register']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.cashierTotal| currency}}</div>
          <div class="widget-subheading">{{$t('generic.lang_cashierTotal')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'">
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-alternate"></div>
            <font-awesome-icon :icon="['fal','ban']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.cancelledTotal| currency}}</div>
          <div class="widget-subheading">{{$t('generic.lang_cancelledTotal')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-primary"></div>
            <font-awesome-icon :icon="['fal','ticket-alt']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.totalTicketsCount}}</div>
          <div class="widget-subheading">{{$t('eventbee.lang_totalTickets')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-alternate"></div>
            <font-awesome-icon :icon="['fas','coins']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.onlineTicketsCount}}</div>
          <div class="widget-subheading">{{$t('eventbee.lang_onlineTickets')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-warning"></div>
            <font-awesome-icon :icon="['fal','store']" size="2x" class="mx-auto"/>
          </div>
          <div class="widget-numbers">{{stats.kioskTicketsCount }}</div>
          <div class="widget-subheading">{{$t('eventbee.lang_kioskTickets')}}</div>
        </div>
      </div>
      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'">
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-success"></div>
            <font-awesome-icon :icon="['fal','cash-register']" size="2x" class="mx-auto"/>
          </div>
          <div class="widget-numbers my-3">{{ stats.cashierTicketsCount}}</div>
          <div class="widget-subheading">{{$t('eventbee.lang_cashierTickets')}}</div>
        </div>
      </div>

      <div class="border" :class="$vuetify.breakpoint.mdAndUp?'col':'col-sm-6 col-12'" >
        <div class="widget-chart">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg bg-danger"></div>
            <font-awesome-icon :icon="['fal','ban']" size="2x" class="mx-auto"/></div>
          <div class="widget-numbers">{{ stats.cancelledTicketsCount}}</div>
          <div class="widget-subheading">{{$t('eventbee.lang_cancelledTickets')}}</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<style scoped>
*{
  z-index: 0;
}
font-awesome-icon{
  color: rgba(0, 0, 0, 0.54) !important;
}
.daily{
  color: #794c8a !important;
}
.widget-numbers{
  font-size: 1.4rem !important;
}
@media only screen and (min-width: 900px) {
  .row-cols-5.no-gutters > .col, .row-cols-5.no-gutters > [class*=col-] {
    border-width: 1px;
  }
}

</style>
<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faInboxIn,faEuroSign,faCalendarDay,faSackDollar,faTachometerAltAverage,faStore,faCashRegister,faBan} from '@fortawesome/pro-light-svg-icons';
import {faCoins,faUserFriends,faAngleUp,faAngleDown,faAngleRight,faUtensils,faWallet,faTicketAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
library.add(
    faInboxIn,faEuroSign,faCalendarDay,faSackDollar,faTachometerAltAverage,faAngleUp,faAngleDown,
    faCoins,faUserFriends,faAngleRight,faUtensils,faWallet,faStore,faCashRegister,faBan,faTicketAlt

);

import {mapState} from "vuex";
import {ENDPOINTS} from "../../../config";

export default {
  name: "EventbeeDailyReportComponent",
  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      loading: true,
      stats:{
        totalTicketsCount:0,
        onlineTicketsCount:0,
        kioskTicketsCount:0,
        cashierTicketsCount:0,
        cancelledTicketsCount:0,
        total:0,
        onlineTotal:0,
        kioskTotal:0,
        cashierTotal:0,
        cancelledTotal:0,
      }
    }
  },

  methods: {
    getEventbeeDailyReport(params) {
      this.loading = true;

      this.axios.post(ENDPOINTS.EVENTBEE.ANALYTICS.DAILYREPORT, params).then((res) => {
        if(res.data.success) {
          this.stats = Object.assign({},res.data.data)
        }
      }).catch(err=>{

      }).finally(()=>{
        this.loading = false;
      })
    },
  }

}
</script>
