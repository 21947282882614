<template>
  <div>
    <div
      :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
      class="card mb-4 main-card"
    >
      <div class="card-body">
        <v-row>
          <v-col class="py-0" cols="12" md="4">
            <v-switch
              v-model="isBookingsToday"
              :label="$t('generic.lang_bookingsTodayOrGuestsToday')"
            />
          </v-col>

          <v-col class="pb-0" cols="12" md="8">
            <v-select
              :items="this.dateOptions"
              class="mx-auto"
              dense
              item-text="name"
              item-value="id"
              outlined
              v-model="dateOption"
            />
          </v-col>

          <v-col class="py-0" cols="12" sm="6" v-if="dateOption == 6">
            <BaseDateInput
              dense
              :label="$t('generic.lang_from')"
              outlined
              type="date"
              :rules="[(v) => !!v && this.validDate]"
              v-model="startDate"
            />
          </v-col>

          <v-col class="py-0" cols="12" sm="6" v-if="dateOption == 6">
            <BaseDateInput
              dense
              :label="$t('generic.lang_till')"
              outlined
              type="date"
              :rules="[(v) => !!v && this.validDate]"
              v-model="endDate"
            />
          </v-col>

          <v-col class="py-0 d-flex" cols="12">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="this.loading || (this.dateOption == 6 && (!this.startDate || !this.endDate || !this.validDate))"
              :loading="this.loading"
              @click="filter()"
              block
              class="bg-primary text-light mx-auto"
            >
              <v-icon class="ma-1">filter_alt</v-icon>
              {{ this.$t("generic.lang_filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "EventbeeStatisticsFiltersComponent",

  components: { BaseDateInput },

  data() {
    return {
      loading: false,
      isBookingsToday: false,
      dateOption: 1,
      startDate: "",
      endDate: "",
    };
  },
  computed: {
    dateOptions() {
      return [
        {
          id: 1,
          name: this.$t("generic.lang_today"),
        },

        {
          id: 2,
          name: this.$t("generic.lang_thisWeek"),
        },
        {
          id: 3,
          name: this.$t("generic.lang_last_week"),
        },
        {
          id: 4,
          name: this.$t("generic.lang_this_month"),
        },
        {
          id: 5,
          name: this.$t("generic.lang_last_month"),
        },
        {
          id: 6,
          name: this.$t("generic.lang_from_to"),
        },
      ];
    },
    validDate() {
      if (this.startDate && this.endDate) {
        return this.getFrom() <= this.getTo();
      } else return true;
    },
  },
  methods: {
    getFrom() {
      let startDate = new Date(this.startDate);
      return moment(startDate).format("DD.MM.YYYY");
    },
    getTo() {
      let endDate = new Date(this.endDate);
      return moment(endDate).format("DD.MM.YYYY");
    },
    getThisWeek() {
      let current = Date.now();
      return moment(current).startOf("weeks").format("DD.MM.YYYY");
    },
    getLastWeek() {
      let current = Date.now();
      return moment(current).startOf("weeks").subtract(1, "weeks");
    },
    getThisMonth() {
      let current = Date.now();
      return moment(current).startOf("months").format("DD.MM.YYYY");
    },
    getLastMonth() {
      let current = Date.now();
      return moment(current).startOf("months").subtract(1, "months");
    },
    getForm() {
      let form = {};
      let now = Date.now();

      if (this.dateOption == 1)
        form = {
          startDate: moment(now).startOf("days").format("DD.MM.YYYY"),
          endDate: moment(now).format("DD.MM.YYYY"),
        };
      else if (this.dateOption == 2)
        form = {
          startDate: this.getThisWeek(),
          endDate: moment(now).format("DD.MM.YYYY"),
        };
      else if (this.dateOption == 3)
        form = {
          startDate: this.getLastWeek().format("DD.MM.YYYY"),
          endDate: moment(this.getLastWeek())
            .endOf("weeks")
            .format("DD.MM.YYYY"),
        };
      else if (this.dateOption == 4)
        form = {
          startDate: this.getThisMonth(),
          endDate: moment(now).format("DD.MM.YYYY"),
        };
      else if (this.dateOption == 5) {
        form = {
          startDate: this.getLastMonth().format("DD.MM.YYYY"),
          endDate: moment(this.getLastMonth())
            .endOf("months")
            .format("DD.MM.YYYY"),
        };
      } else if (this.dateOption == 6) {
        form = {
          startDate: this.getFrom(),
          endDate: this.getTo(),
        };
      }
      form.isBookingsToday = this.isBookingsToday;
      return form;
    },
    filter() {
      this.loading = true;
      this.$emit("filterStatistics", this.getForm());
      this.loading = false;
    },
  },
};
</script>

<style scoped>
* {
  z-index: 0;
}

.card-body {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
</style>
