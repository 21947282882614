<template>
  <div>
    <page-title :heading="'EventBee'" :icon=icon :subheading="$t('generic.lang_dashboard')"
    ></page-title>
    <div class="app-main__inner">
      <EventbeeAnalyticsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EventbeeAnalyticsComponent from "../../components/eventbee/analytics/EventbeeAnalyticsComponent";
export default {
  name: "EventOrders",
  components: {EventbeeAnalyticsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-ticket icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>