<template>
  <div>
    <EventbeeStatisticsFiltersComponent @filterStatistics="filterStatistics" />
    <EventbeeDailyReportComponent ref="dailyReport"/>
    <EventbeeEventsStatisticsComponent ref="eventsStatistics"/>
  </div>
</template>

<script>
import moment from "moment";
import EventbeeStatisticsFiltersComponent from "./EventbeeStatisticsFiltersComponent";
import EventbeeDailyReportComponent from "./EventbeeDailyReportComponent";
import EventbeeEventsStatisticsComponent from "./EventbeeEventsStatisticsComponent";

export default {
  name: "EventbeeAnalyticsComponent",
  components: {EventbeeStatisticsFiltersComponent, EventbeeEventsStatisticsComponent, EventbeeDailyReportComponent, },
  
  methods: {
    filterStatistics(params) {
      this.$refs.dailyReport.getEventbeeDailyReport(params);
      this.$refs.eventsStatistics.getAllEventsReport(params);
    }
  },
  mounted() {
    let now = Date.now();
    let params = {
      startDate: moment(now).format("DD.MM.YYYY"),
      endDate: moment(now).format("DD.MM.YYYY"),
      isBookingsToday: false
    }
    this.filterStatistics(params);
  },
}
</script>

<style scoped>

</style>