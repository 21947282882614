<template>
  <div>
    <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card mb-3 main-card" >
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <font-awesome-icon :icon="['fal','ticket-alt']" class="kassen pr-2" size="2x"/>
          {{$t('eventbee.lang_eventsStats')}}
        </div>
      </div>
      <div class="card-body mh-75 overflow-auto">
        <div v-if="!loading" class="row" >
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"  v-for="(item, index) in data.events" :key="index+'-events'">
            <div :class="[$vuetify.theme.dark? 'dark-bg' : '', 'widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning card '+border_colors[Math.floor(Math.random()*border_colors.length)]]">
              <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                  <div class="row no-gutters">
                    <div class="col-12">
                      <div class="widget-title opacity-5 text-uppercase">#{{item.name}}</div>
                    </div>
                  </div>

                  <div class="widget-numbers mt-2 mb-0 w-100">
                    <div class="widget-chart-flex font-size-md align-items-center">
                      <div class="no-gutters row w-100">
                        <div class="col-md-6">
                          <div class="pa-0 card-body">
                            <ul :class="[$vuetify.theme.dark? 'dark-bg' : 'white']" class="list-group list-group-flush">
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('generic.lang_total')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-success">
                                          {{item.total | currency}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('generic.lang_places')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-primary">
                                          {{item.kontingent}}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="pa-0 card-body">
                            <ul :class="[$vuetify.theme.dark? 'dark-bg' : 'white']" class="list-group list-group-flush">
                              <li class="list-group-item  pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('eventbee.lang_soldTickets')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-danger">
                                          {{item.ticketSolds }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('eventbee.lang_refundAndCancelation')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-warning">
                                          {{item.voids }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            :active="loading"
            height="6"
        />
      </div>
    </div>
    <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card mb-3 main-card" >
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <font-awesome-icon :icon="['fal','ticket-alt']" class="kassen pr-2" size="2x"/>
          {{$t('eventbee.lang_eventGroupsStats')}}
        </div>
      </div>
      <div class="card-body mh-75 overflow-auto">
        <div v-if="!loading" class="row" >
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"  v-for="(item, index) in data.groups" :key="index+'-groups'">
            <div :class="[$vuetify.theme.dark? 'dark-bg' : '', 'widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning card '+border_colors[Math.floor(Math.random()*border_colors.length)]]">
              <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                  <div class="row no-gutters">
                    <div class="col-12">
                      <div class="widget-title opacity-5 text-uppercase">#{{item.name}}</div>
                    </div>
                  </div>

                  <div class="widget-numbers mt-2 mb-0 w-100">
                    <div class="widget-chart-flex font-size-md align-items-center">
                      <div class="no-gutters row w-100">
                        <div class="col-md-6">
                          <div class="pa-0 card-body">
                            <ul :class="[$vuetify.theme.dark? 'dark-bg' : 'white']" class="list-group list-group-flush">
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('generic.lang_total')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-success">
                                          {{item.total | currency}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('generic.lang_places')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-primary">
                                          {{item.kontingent }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="pa-0 card-body">
                            <ul :class="[$vuetify.theme.dark? 'dark-bg' : 'white']" class="list-group list-group-flush">
                              <li class="list-group-item  pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('eventbee.lang_soldTickets')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-danger">
                                          {{item.ticketSolds }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="list-group-item pa-0 pl-1 transparent">
                                <div class="widget-content p-0">
                                  <div class="widget-content-outer">
                                    <div class="widget-content-wrapper">
                                      <div class="widget-content-left">
                                        <div class="widget-subheading">{{$t('eventbee.lang_refundAndCancelation')}}</div>
                                      </div>
                                      <div class="widget-content-right">
                                        <div class="widget-numbers text-warning">
                                          {{item.voids}}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            :active="loading"
            height="6"
        />
      </div>
    </div>

  </div>
</template>
<script>
import {mapState} from "vuex";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faTicketAlt} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "../../../config";
library.add(faTicketAlt,);

export default {
  name: "EventbeeEventsStatisticsComponent",
  components:{
    FontAwesomeIcon,
  },
  data(){
    return {
      loading: true,
      totalDaily:{},
      data: {
        events: null,
        groups: null
      },
      border_colors:[
        "border-primary",
        "border-success",
        "border-danger",
        "border-info",
        "border-alternate",
        "border-warning",
      ],
      f5: {gradient: ["#d92550"]},
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
  },

  methods: {
    async getAllEventsReport(params) {
      this.loading = true;

      this.axios.post(ENDPOINTS.EVENTBEE.ANALYTICS.EVENTSANDGROUPSREPORT, params).then((res) => {
        if(res.data.success) {
          this.data.events = Object.values(res.data.data.events);
          this.data.groups = Object.values(res.data.data.groups);
        }
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      })
    },
    getPercent(){
      return Math.random() * 100;
    }
  }
}
</script>
<style scoped>
*{
  z-index: 0;
}

.kassen{
  color: #794c8a !important;
}

.card-body{
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.widget-subheading{
  font-size:0.8rem !important;
}

.widget-numbers .widget-numbers{
  font-size:0.9rem !important;
}

</style>

